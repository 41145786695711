import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';
import logo from '../assets/img/logo.png';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const createWidget = async (logoUrl) => {
  const widgetApiHandler = new ApiStore(WIDGET_API_URL)

  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get("id");

  const planData = await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    logo: logo,
    tabs: ['panorama', 'rotation'],
    locale: 'en',
    logoUrl,
    dictionaryOverrides: {
      'made-by-text': 'spsetia.com',
      'made-by-link': 'https://spsetia.com/en-us/property/malaysia-(central)/semenyih/setia-ecohill/collections/harumi-residences',
      'instructions-hint-text': 'The virtual tour is provided solely for illustration purposes. Any use of the virtual tour for assessing the property is not recommended. The seller, agent, or associated parties are not liable for any inaccuracies or discrepancies in the virtual tour.'
    },
  }

  new Widget('#widget', options);
}

const changeFloorsVisible = (floors) => {
  if (floors.classList.contains('hidden')) {
    floors.classList.remove('hidden');
  }
  else floors.classList.add('hidden');
}

const changeFloorTitle = (name) => {
  if (name.innerHTML.includes('Ground')) {
    name.innerHTML = 'First floor';
  }
  else name.innerHTML = 'Ground floor';
};

const createNewFloors = () => {
  const widget = document.querySelector('.widget-application');
  const floors = document.getElementById('floors-select');

  // clone floor elements and create new floor block 
  const newFloors = floors.cloneNode(true);
  newFloors.classList.add('floors-new');
  const newTitle = newFloors.querySelector('.floors-select--text');
  newTitle.innerHTML = 'Ground floor'
  widget.append(newFloors);

  const newBlock = document.createElement("div");
  newBlock.classList.add('hidden');
  newBlock.classList.add('floors-select--block');
  const floor1 = document.createElement("div");
  floor1.classList.add('floors-select--block-item');
  floor1.classList.add('floors-select--block-item--active');
  floor1.innerHTML = 'Ground floor';
  const floor2 = document.createElement("div");
  floor2.classList.add('floors-select--block-item');
  floor2.innerHTML = 'First floor';
  newBlock.append(floor1);
  newBlock.append(floor2);
  newFloors.append(newBlock);

  // repeate all clicks
  newFloors.addEventListener('click', (e) => {
    if (e.target.className === 'floors-select--text') {
      floors.querySelector('.floors-select--text').click();
    }
    else if (e.target.className.includes('--active')) {
      floors.querySelector('.floors-select--block-item--active').click();
    }
    else if (e.target.className === 'floors-select--block-item') {
      changeFloorTitle(newTitle);
      floors.querySelector('.floors-select--block-item:not(.floors-select--block-item--active)').click();
      e.target.classList.add('floors-select--block-item--active');
    }
  });

  // additional actions for new floor block
  floors.addEventListener('click', (e) => {
    if (e.target.className === 'floors-select--text') {
      changeFloorsVisible(newBlock);
    }
    if (e.target.className.includes('--active')) {
      changeFloorsVisible(newBlock);
    }
    else if (e.target.className === 'floors-select--block-item') {
      changeFloorsVisible(newBlock);
      newFloors.querySelector('.floors-select--block-item--active').classList.remove('floors-select--block-item--active');
    }
  });

  window.addEventListener('click', (e) => {
    if (!e.target.className.includes('floors')) {
      newBlock.classList.add('hidden');
    }
  })
};

createWidget('https://spsetia.com/en-us/property/malaysia-(central)/semenyih/setia-ecohill/collections/harumi-residences').then(() => {
  createNewFloors();
});
